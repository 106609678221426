var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sideNav"},[_c('div',{class:("nav relative " + (_vm.isShowMenu ? 'show' : ''))},[_c('div',{class:("menubg " + (_vm.isOpen ? 'open' : ''))}),_c('div',{class:("menu " + (_vm.isOpen ? 'open' : '')),on:{"click":_vm.toggleSidebar}},[(_vm.isOpen)?_c('img',{staticClass:"close",attrs:{"src":require("@/projects/share/close.png"),"alt":""}}):_vm._e()]),_c('div',{class:("mask " + (_vm.isOpen ? 'open' : '')),on:{"click":_vm.toggleSidebar}}),_c('ul',{class:("navlist " + (_vm.isOpen ? 'open' : ''))},_vm._l((_vm.list),function(item){return _c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          element: ("#" + (item.section)),
          offset: _vm.isMobile
            ? item.mobileOffset
              ? item.mobileOffset
              : _vm.offset
            : item.offset
            ? item.offset
            : _vm.offset
        }),expression:"{\n          element: `#${item.section}`,\n          offset: isMobile\n            ? item.mobileOffset\n              ? item.mobileOffset\n              : offset\n            : item.offset\n            ? item.offset\n            : offset\n        }"}],key:item.name,staticClass:"flex-ac",on:{"click":_vm.toggleSidebar}},[_c('span',{staticClass:"link"},[(item.imgSrc)?_c('img',{attrs:{"src":item.imgSrc,"alt":""}}):_vm._e(),_c('span',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"subTitle"},[_vm._v(_vm._s(item.subTitle))])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }